.Logo {
  width: 450px;
  height: 250px;
}

.Header {
  text-align: center;
}

.Note {
  color: #ee2734;
  font-weight: 600;
  font-family: 'Courier New', Courier, monospace;
}

.orderUs {
  text-align: center;
  margin-top: 5%;
}

.orderUsText {
  color: #ee2734;
  font-size: large;
}

.number {
  color: #ee2734;
  font-weight: bolder;
  font-size: xx-large;
  text-decoration: none;
}

